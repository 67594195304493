<template>
    <div class="m-2">
        <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
        <modalComparacionList :loadPort="loadPort" :dischargePort="dischargePort" :resumidoOption="resumidoOption" :filtrarOption="filtrarOption" />
        <CRow style="margin-bottom:10px;">
            <CCol sm="12">
                <CButton
                    color="watch"
                    @click="abrirModal"
                    v-c-tooltip="{
                        content: `${$t('label.selectFilesElectronics')}`,
                        placement: 'top-end',
                    }"
                >
                    <CIcon name="cil-list"/>&nbsp; {{$t('label.selectFile')+'S'}}
                </CButton>
            </CCol>
            <CCol sm="12" style="margin-top:10px;">
                <CRow>
                    <CCol sm="5">
                        <CSelect
                            :label="$t('label.dischargePort')"
                            horizontal
                            :disabled="!showComparationGroup"
                            :value.sync="dischargePort"
                            :options="optionListDischargeAll"
                            @change="changePort"
                        />
                    </CCol>
                    <CCol sm="5">
                        <CSelect
                            :label="$t('label.loadPort')"
                            horizontal
                            :disabled="!showComparationGroup"
                            :value.sync="loadPort"
                            :options="optionListLoadAll"
                            @change="changePort"
                        />
                    </CCol>
                    <CCol sm="2">
                        <CButton
                            color="edit"
                            @click="resetValues()"
                            v-if="showComparationGroup"
                            v-c-tooltip="{
                                content: `${$t('label.reset').replace(/ /g, '')}`,
                                placement: 'top-end',
                            }"
                        >
                            <CIcon name="cil-loop-circular"/>
                        </CButton>
                    </CCol>
                    <CCol sm="5">
                        <CSelect
                            :label="$t('label.watch')"
                            horizontal
                            :disabled="!showComparationGroup"
                            :value.sync="resumidoOption"
                            :options="optionListVerAll"
                            @change="changeVerPort"
                        />
                    </CCol>
                    <CCol sm="5">
                        <CSelect
                            :label="$t('label.filterBy')"
                            horizontal
                            :disabled="!(resumidoOption == 2) || !showComparationGroup"
                            :value.sync="filtrarOption"
                            :options="optionListFiltrarAll"
                            @change="changePort"
                        />
                    </CCol>
                </CRow>
            </CCol>
            <CCol style="margin-top:10px;" sm="12" v-if="showComparationGroup && this.resumidoOption !== null">
                <CRow >
                    <CCol sm="12" v-if="this.resumidoOption === 1">
                        <div class="bd-highlight">
                            <ag-grid-vue
                                v-if="showGrid"
                                style="width: 100%; height: 100vh;"
                                class="ag-theme-alpine"
                                :gridOptions="gridOptions"
                                :defaultColDef="defaultColDef"
                                :localeText="localeText"
                                :columnDefs="modeTableGeneral"
                                :rowData="formatedItems"
                                :pagination="true"
                                :paginationPageSize="paginationPageSize"
                                :paginationNumberFormatter="paginationNumberFormatter"
                                @grid-ready="onGridReady"
                            >
                            </ag-grid-vue>
                        </div>
                    </CCol>
                    <CCol sm="12" v-else>
                        <div class="bd-highlight">
                            <ag-grid-vue
                                v-if="showGrid"
                                style="width: 100%; height: 100vh;"
                                class="ag-theme-alpine"
                                :gridOptions="gridOptions"
                                :defaultColDef="defaultColDef"
                                :localeText="localeText"
                                :columnDefs="modeTableResum"
                                :rowData="formatedItems"
                                :pagination="true"
                                :paginationPageSize="paginationPageSize"
                                :paginationNumberFormatter="paginationNumberFormatter"
                                @grid-ready="onGridReady"
                            >
                            </ag-grid-vue>
                        </div>
                    </CCol>
                    <CCol sm="12" style="margin-top:10px;" class="d-flex align-items-center justify-content-end">
                        <CButton
                            color="excel" class="mr-1" @click="onBtnExport(true)"
                            >
                                <CIcon name="cil-file-excel"/>&nbsp; XSLX
                        </CButton>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import modalComparacionList from './modalComparacionListados';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import Reportes from '@/_mixins/reportes';
    import AgGrid from '@/_mixins/ag-grid';
    import {saveAs} from "file-saver";
    import Excel from "exceljs/dist/es5/exceljs.browser";
    import { DateFormater } from '@/_helpers/funciones';
    function data(){
        return {
            defaultColDef: null,
            gridOptions: null,
            gridApi: null,
            columnApi: null,
            paginationPageSize:null,
            paginationNumberFormatter: null,
            loadPort:null,
            dischargePort:null,
            resumidoOption:1,
            filtrarOption:null,
            loadingOverlay: false
        }
    }
    //beforeMount
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    //methods
    async function getExcelArray2(rowData, titulo, excel, companyName) {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet(titulo);
        const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

        let y = 0;
        let letra = 12;
        let columnsDefs = [];
        let keyColumns = [];
        let bandera = true;

        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera = false;

        //if (isUndefined(un)) un = 'TON';
        if (excel) {
            if(companyName === undefined){
                const Index = this.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(this.ItineraryId);
                
                if(Index > -1){      
                    this.dataBuque.DatePlanning = this.myDataItinirary[Index].VesselName+" "+this.myDataItinirary[Index].Imo+", "+this.myDataItinirary[Index].CountryName+", ETA: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta)+", ETD: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd)+" - "+this.$t('label.voyage')+": "+this.myDataItinirary[Index].Voyage;
                    this.dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
                } else {
                    let itinerarySelected = this.itinerarySelected;
                    this.dataBuque.DatePlanning = `${itinerarySelected.VesselName}, - ${this.$t('label.voyage')}: ${itinerarySelected.Voyage}, ${itinerarySelected.LabelArrival} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Arrival)} - ${itinerarySelected.LabelDeparture} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Departure)}`;
                    this.dataBuque.CountryName = this.itinerarySelected.CountryName;
                    this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
                }
            }
            if (y < 10) letra = 8;
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path: this.user.LogoRoute.replace('Public/', '', null, 'i')
            });

            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;

            if(companyName){
                worksheet.mergeCells("C1:" + this.numeroLetra(y) + "2")   
            }else if(companyName === undefined){ 
                worksheet.mergeCells("C1:" + this.numeroLetra(y) + "1");
                worksheet.mergeCells("C2:" + this.numeroLetra(y) + "2");
            }

            const customCellCompanyBranch = worksheet.getCell("C1");

            const row1 = worksheet.getRow(1);
            row1.height = companyName ? 60 : 30;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                
            if(companyName){
            }else if(companyName === undefined){     
                const row2 = worksheet.getRow(2);    
                row2.height = 30;
                const customCell = worksheet.getCell("C2");
                customCell.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    }
                }
                customCell.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                customCell.value = this.dataBuque.DatePlanning;
                customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            }

            //titulo
                worksheet.getRow(3).height = 30;
                worksheet.mergeCells("A3:" + this.numeroLetra(y) +"3");
                const customCell2 = worksheet.getCell("A3");
                customCell2.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell2.value = titulo;
                customCell2.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }

                //COLUMNA 2
                let division = Math.floor(y / 3);
                let rango1 = this.numeroLetra(division);
                let rango11 = this.numeroLetra(division+1);
                let rango2 = this.numeroLetra(division*2);
                let rango21 = this.numeroLetra((division * 2) +1);
                let rango3 = this.numeroLetra(y);
                
                const hoy = new Date();

                if(companyName){
                    worksheet.mergeCells("A4:" + rango1 +"4");
                    const customCellCompany = worksheet.getCell("A4");
                    worksheet.mergeCells(rango11 + "4:" + rango2 + "4");
                    worksheet.mergeCells(rango21 + "4:" + rango3 + "4");

                    customCellCompany.height = 50;
                    customCellCompany.font = {
                        name: "Calibri",
                        family: 4,
                        size: 12,
                        underline: false,
                        bold: true
                    };

                    customCellCompany.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
                    customCellCompany.value = this.$t('label.company')+": "+companyName;
                    customCellCompany.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                        left: { style: 'thin' },
                    }
                }else if(companyName === undefined){
                    worksheet.mergeCells("A4:" + rango2 +"4"); //fecha de impresion
                    worksheet.mergeCells(rango21 + "4:" + rango3 + "4");  //usuario
                }

                const customCell6 = companyName ? worksheet.getCell(rango11 + "4") : worksheet.getCell("A4");
                customCell6.height = 50;
                customCell6.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };

                customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
                customCell6.value = this.$t('label.printed')+": "+hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
                customCell6.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }

                //usuario
                const customCell7 = companyName ? worksheet.getCell(rango21 + "4") : worksheet.getCell(rango21 + "4");
                customCell7.height = 50;
                customCell7.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };

                customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
                customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
                customCell7.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                }

                //linea
                worksheet.mergeCells("A5:" + rango3 +"5");
                const customCell8 = worksheet.getCell("A5");
                customCell8.height = 50;
                customCell8.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

                //columna5
                worksheet.mergeCells("A6:D6");
                const customCelldischargePort = worksheet.getCell("B6");
                customCelldischargePort.height = 50;
                customCelldischargePort.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'f2f2f2' 
                    }
                }
                customCelldischargePort.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCelldischargePort.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCelldischargePort.value = this.$t('label.dischargePort')+': '+ (this.textPortDescarga ? this.textPortDescarga : 'N/A');
                customCelldischargePort.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna6
                worksheet.mergeCells("E6:G6");
                const customCellloadPort = worksheet.getCell("E6");
                customCellloadPort.height = 50;
                customCellloadPort.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'f2f2f2' 
                    }
                }
                customCellloadPort.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCellloadPort.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCellloadPort.value = this.$t('label.loadPort')+': '+ (this.textPortCarga ? this.textPortCarga : 'N/A');
                customCellloadPort.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna7
                worksheet.mergeCells("H6:K6");
                const customCelltextResumido = worksheet.getCell("H6");
                customCelltextResumido.height = 50;
                customCelltextResumido.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'f2f2f2' 
                    }
                }
                customCelltextResumido.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCelltextResumido.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCelltextResumido.value = this.$t('label.watch')+': '+ (this.textResumido ? this.textResumido : 'N/A');
                customCelltextResumido.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna8
                worksheet.mergeCells("L6:O6");
                const customCellfilteredBy = worksheet.getCell("L6");
                customCellfilteredBy.height = 50;
                customCellfilteredBy.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'f2f2f2' 
                    }
                }
                customCellfilteredBy.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCellfilteredBy.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCellfilteredBy.value = this.$t('label.filteredBy')+': '+ (this.textFiltrarPor ? this.textFiltrarPor : 'N/A');
                customCellfilteredBy.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }
        }

        var headerRow = worksheet.addRow();
        headerRow.font = {
            name: "Calibri",
            family: 4,
            size: 11,
            underline: false,
            bold: true
        };
    /* headerRow.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
        } */
        headerRow.height = 30;

        let i = 0;
        columnsDefs.map(function (data) {
            let valor = bandera ? data.headerName.length : data.length;
            let long = 10;
            if ((valor >= 5) && (valor <= 12)) {
                long = 20;
            } else if ((valor >= 11) && (valor <= 18)) {
                long = 25;
            } else if ((valor >= 19) && (valor <= 26)) {
                long = 30;
            } else if (valor >= 27) {
                long = 40;
            }

            //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

            worksheet.getColumn(i + 1).width = long;
            //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            
            cell.value = bandera ? data.headerName : data;
            cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }
            //}        
            i++;
        })

        rowData.map(async function (data) {
            if (data.Cont20FtDeck=="DECK") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                //Grupo 1
                worksheet.mergeCells("C7:F7");
                let cellA4 = worksheet.getCell("D7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = { 
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }            
                cellA4.value = data.Cont20FtDeck;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("G7:J7");
                cellA4 = worksheet.getCell("H7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont20FtHold;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("K7:N7");
                cellA4 = worksheet.getCell("L7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont20FtTotal;

                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }

            } else if (data.Cont20Full == "20F") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                worksheet.mergeCells("C6:C7");
                //Grupo 1
                worksheet.mergeCells("D7:E7");
                let cellA4 = worksheet.getCell("D7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.Cont20Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("F7:G7");
                cellA4 = worksheet.getCell("F7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.Cont20Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("H7:I7");
                cellA4 = worksheet.getCell("H7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont40Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 4
                worksheet.mergeCells("J7:K7");
                cellA4 = worksheet.getCell("J7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont40Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 5
                worksheet.mergeCells("L7:M7");
                cellA4 = worksheet.getCell("L7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont45Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 6
                worksheet.mergeCells("N7:O7");
                cellA4 = worksheet.getCell("N7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont45Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {            
                //Grupo 1
                worksheet.mergeCells("N7:P7");
                let cellA4 = worksheet.getCell("N7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.CtaFunctionCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("Q7:R7");
                cellA4 = worksheet.getCell("R7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.ComAddressIdentifier;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("S7:V7");
                cellA4 = worksheet.getCell("S7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.HanDescriptionCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 4
                worksheet.mergeCells("W7:Y7");
                cellA4 = worksheet.getCell("W7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.TsrServicePriorityCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            } else if (data.section=="totalLoad") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                //Grupo 1
                worksheet.mergeCells("C7:E7");
                let cellA4 = worksheet.getCell("C7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = { 
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    },
                }            
                cellA4.value = "DECK";
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("F7:H7");
                cellA4 = worksheet.getCell("F7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = "HOLD";
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            }else {
                let dataRow = worksheet.addRow();
                let indice = 0;
                
                keyColumns.map(function (data2) {
                    let cell = dataRow.getCell(indice + 1);
                    cell.value = data[data2];
                    //if (cell.value==)
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    indice++;
                })
            }
        })

        let fileName = documento;
        let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
        today = today.replaceAll("/", "");
        let time = new Date();
        time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
        //return false;
        if (excel) {
            workbook.xlsx.writeBuffer()
                .then(buffer => {
                    saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                    this.$notify({
                        group: 'container',
                        text: this.$t('label.reportSuccessfully'),
                        type: "success"
                    });
                }).catch(err => {
                    console.log(err);
                })
        } else {
            workbook.csv.writeBuffer()
                .then(buffer => {
                    saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                    this.$notify({
                        group: 'container',
                        text: this.$t('label.reportSuccessfully'),
                        type: "success"
                    });
                })
        }
    }
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }
    function resetValues(){
        this.loadPort=null;
        this.dischargePort=null;
        this.resumidoOption=1;
        this.filtrarOption=null;
        this.loadListItems = this.$store.getters["planificacionestiba/getDataComparationLoad"];
        this.dischargeListItems = this.$store.getters["planificacionestiba/getDataComparationDischarge"];
        this.preliminarListItems = this.$store.getters["planificacionestiba/getDataComparationPreliminar"];

        this.loadListItems.map(async(item) => {
            item.change = false;
        });
        this.dischargeListItems.map(async(item) => {
            item.change = false;
        });
        this.preliminarListItems.map(async(item) => {
            item.change = false;
        });

        this.$store.state.planificacionestiba.modalComparacionList = false;
        this.$store.state.planificacionestiba.showComparationGroup = false;
        this.$store.state.planificacionestiba.FileComparationList = [];
    }
    function abrirModal(){
        this.$store.state.planificacionestiba.modalComparacionList = true;
    }
    function changePort(){
        let loadListItems = this.$store.getters["planificacionestiba/getDataComparationLoad"];
        let dischargeListItems = this.$store.getters["planificacionestiba/getDataComparationDischarge"];
        let preliminarListItems = this.$store.getters["planificacionestiba/getDataComparationPreliminar"];

        let chart = [];
        loadListItems.map(async(item) => {
            if(item.change){    
                chart.push({
                    EdiFileId: item.EdiFileId
                })
            }
        })
        dischargeListItems.map(async(item) => {
            if(item.change){    
                chart.push({
                    EdiFileId: item.EdiFileId
                })
            }
        })
        preliminarListItems.map(async(item) => {
            if(item.change){    
                chart.push({
                    EdiFileId: item.EdiFileId
                })
            }
        })

        if(chart.length !== 0 && chart.length > 1){
            this.$store.dispatch('planificacionestiba/postFileCompartionList', { LoadPortId: this.loadPort,DischargePortId:this.dischargePort,FileJson:chart, resumidoOption:this.resumidoOption,filtrarOption:this.filtrarOption });
        }
    }
    function changeVerPort(event){
        if(this.resumidoOption === 1){
            this.filtrarOption = null;
        }else{
            this.filtrarOption = 1;
        }
        this.changePort();
    }
    async function onBtnExport(valor){
        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
        if(this.resumidoOption === 1 || this.resumidoOption === null){
            await this.getExcelArray2(this.dataExcel,"LISTADO DE ARCHIVOS ELECTRONICOS",valor);
            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
        }else{
            try{
                const workbook = new Excel.Workbook();
                const worksheet = workbook.addWorksheet("LISTADO DE ARCHIVOS ELECTRONICOS");

                const Index = this.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(this.ItineraryId);

                let dataBuque = {
                    DatePlanning:'',
                    CountryName:'',
                    VesselFlagRoute:'',
                }

                if(Index > -1){
                    dataBuque.DatePlanning = this.myDataItinirary[Index].DatePlanning;
                    dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
                    dataBuque.VesselFlagRoute = this.myDataItinirary[Index].VesselFlagRoute;

                    worksheet.mergeCells('A1:A1');
                    const response1 = await this.$http.getFile('base64', {
                        path:this.user.LogoRoute.replace('Public/','', null, 'i')
                    });
                    const divisiones1 = this.user.LogoRoute.split(".");
                    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;
                    const imageId = workbook.addImage({
                      base64: myBase64Image,
                      extension: divisiones1[1],
                    });
                    worksheet.addImage(imageId, 'A1:B1');

                    const rowIndex1 = worksheet.getRow(1);
                    rowIndex1.height = 100;

                    worksheet.mergeCells("C1:V1");

                    const customCell = worksheet.getCell("C1");
                    const row = worksheet.getRow(1);
                    row.height = 90;
                    customCell.font = {
                        name: "Calibri",
                        family: 4,
                        size: 12,
                        underline: false,
                        bold: true
                    };
                    customCell.note = {
                        margins : {
                            insetmode: 'auto',
                            inset: [10, 10, 5, 5]
                        }
                    }
                    customCell.fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{
                            argb:'f2f2f2' 
                        }
                    }
                    customCell.alignment = { vertical: 'middle', horizontal: 'center' }
                    customCell.value = dataBuque.DatePlanning;
                    customCell.border = {
                        top: {style:'thin'},
                        bottom: {style:'thin'},
                        left: {style:'thin'},
                        right: {style:'thin'},
                    }
                }
                //COLUMNA 2
                worksheet.mergeCells("A2:V2");
                const customCell2 = worksheet.getCell("A2");
                customCell2.height = 100;
                customCell2.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell2.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'f2f2f2' 
                    }
                }
                customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell2.value = "LISTADO DE ARCHIVOS ELECTRONICOS";
                customCell2.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna 3
                worksheet.mergeCells("A3:J3");
                const customCell3 = worksheet.getCell("A3");
                customCell3.height = 50;
                customCell3.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'f2f2f2' 
                    }
                }
                customCell3.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                const hoy = new Date();
                customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell3.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy); //"IMPRESO:  "+hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
                customCell3.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }
                //COLUMNA 4
                worksheet.mergeCells("K3:V3");
                const customCell4 = worksheet.getCell("K3");
                customCell4.height = 50;
                customCell4.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'f2f2f2' 
                    }
                }
                customCell4.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell4.value = "LOGIN DE USUARIO: "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
                customCell4.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna5
                worksheet.mergeCells("B5:E5");
                const customCell5 = worksheet.getCell("B5");
                customCell5.height = 50;
                customCell5.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'f2f2f2' 
                    }
                }
                customCell5.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell5.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell5.value = this.$t('label.dischargePort')+': '+ this.textPortDescarga;
                customCell5.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna6
                worksheet.mergeCells("F5:J5");
                const customCell6 = worksheet.getCell("F5");
                customCell6.height = 50;
                customCell6.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'f2f2f2' 
                    }
                }
                customCell6.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell6.value = this.$t('label.loadPort')+': '+ this.textPortCarga;
                customCell6.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna7
                worksheet.mergeCells("K5:P5");
                const customCell7 = worksheet.getCell("K5");
                customCell7.height = 50;
                customCell7.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'f2f2f2' 
                    }
                }
                customCell7.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell7.value = this.$t('label.watch')+': '+ this.textResumido;
                customCell7.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna8
                worksheet.mergeCells("Q5:V5");
                const customCell8 = worksheet.getCell("Q5");
                customCell8.height = 50;
                customCell8.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'f2f2f2' 
                    }
                }
                customCell8.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell8.value = this.$t('label.filteredBy')+': '+ this.textFiltrarPor;
                customCell8.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna10
                worksheet.mergeCells("B6:D6");
                const customCell10 = worksheet.getCell("B6");
                customCell10.height = 50;
                customCell10.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell10.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell10.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell10.value = "HC";
                customCell10.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna11
                worksheet.mergeCells("E6:G6");
                const customCell11 = worksheet.getCell("E6");
                customCell11.height = 50;
                customCell11.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell11.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell11.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell11.value = "ST";
                customCell11.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna12
                worksheet.mergeCells("H6:J6");
                const customCell12 = worksheet.getCell("H6");
                customCell12.height = 50;
                customCell12.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell12.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell12.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell12.value = "RF";
                customCell12.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna13
                worksheet.mergeCells("K6:M6");
                const customCell13 = worksheet.getCell("K6");
                customCell13.height = 50;
                customCell13.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell13.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell13.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell13.value = "FT";
                customCell13.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna14
                worksheet.mergeCells("N6:P6");
                const customCell14 = worksheet.getCell("N6");
                customCell14.height = 50;
                customCell14.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell14.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell14.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell14.value = "OT";
                customCell14.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna15
                worksheet.mergeCells("Q6:S6");
                const customCell15 = worksheet.getCell("Q6");
                customCell15.height = 50;
                customCell15.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell15.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell15.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell15.value = "TK";
                customCell15.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }

                //columna16
                worksheet.mergeCells("T6:V6");
                const customCell16 = worksheet.getCell("T6");
                customCell16.height = 50;
                customCell16.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell16.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell16.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell16.value = "PL";
                customCell16.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }
                
                var headerRow = worksheet.addRow();
                headerRow.font =  {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                headerRow.height = 30;
                let i = 1;
                let texto = '';
                while(i <= 21){
                    let width = 8;
                    worksheet.getColumn(i + 1).width = width;
                    if(texto == '' || texto == "45"){
                        texto = "20";
                    }else if(texto == '20'){
                        texto = "40";
                    }else if(texto == '40'){
                        texto = "45";
                    }
                    let cell = headerRow.getCell(i + 1);
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'cccccc'
                        }
                    }
                    
                    cell.value = texto;
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    i++;
                }

                //columna9
                worksheet.mergeCells("A6:A7");
                const customCell9 = worksheet.getCell("A6");
                customCell9.height = 50;
                customCell9.width = 150;
                customCell9.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{
                        argb:'cccccc'
                    }
                }
                customCell9.font = {
                    name: "Calibri",
                    family: 4,
                    size: 12,
                    underline: false,
                    bold: true
                };
                customCell9.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCell9.value = this.filtrarOption == null || this.filtrarOption == 1 ? this.$t('label.newShippingLine') : this.$t('label.status');
                customCell9.border = {
                    top: {style:'thin'},
                    bottom: {style:'thin'},
                    left: {style:'thin'},
                    right: {style:'thin'},
                }
                const dobCol = worksheet.getColumn('A');
                dobCol.width = this.filtrarOption == null || this.filtrarOption == 1 ? 25 : 20;


                this.dataExcel.forEach(async(value) => {
                    let dataRow = worksheet.addRow();
                    let indice = 0;
                    value.forEach(async(item) => {
                        let cell = dataRow.getCell(indice + 1);
                        cell.value = item;
                        cell.border = {
                            top: { style: 'thin' },
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                        indice++;
                    })
                })
                // save under export.xlsx
                workbook.xlsx.writeBuffer()
                .then(buffer => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    let hoy = new Date();
                    let time = hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false });
                    let today = DateFormater.formatOnlyDateWithSlash(new Date());
                    today = today.replaceAll("/", "");
                    const documento = this.textFiltrarPor.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
                    let titulo = (`ListadoArchivoElectronico${documento}${today}${time}.xlsx`).replaceAll(':','');
                    saveAs(new Blob([buffer]), titulo)
                    this.$notify({
                        group: 'container',
                        text: 'Reporte generado exitosamente',
                        type: "success"
                    });
                })
                .catch(err => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    console.log('Error writing excel export', err)
                })
            }catch (error) {
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                this.$store.commit('planificacionestiba/messageMutation', error);
            }
        } 
    }
    
    //computed
    function textPortDescarga(){
        if(this.dischargePort === '' || this.dischargePort === null ){
            return '';
        }else{
            let text = '';
            this.ComparationDischarge.map(async (e) => {
                if(this.dischargePort === e.PortId){
                    text = e.PortCode;
                }
            })
            return text;
        }
    }
    function textPortCarga(){
        if(this.loadPort === '' || this.loadPort === null ){
            return '';
        }else{
            let text = '';
            this.ComparationLoad.map(async (e) => {
                if(this.loadPort === e.PortId){
                    text = e.PortCode;
                }
            })
            return text;
        }
    }
    function textResumido(){
        if(this.resumidoOption === '' || this.resumidoOption === null ){
            return '';
        }else{
            let text = '';
            this.optionListVerAll.map(async (e) => {
                if(this.resumidoOption === e.value){
                    text = e.label;
                }
            })
            return text;
        }
    }
    function textFiltrarPor(){
        if(this.filtrarOption === '' || this.filtrarOption === null ){
            return '';
        }else{
            let text = '';
            this.optionListFiltrarAll.map(async (e) => {
                if(this.filtrarOption === e.value){
                    text = e.label;
                }
            })
            return text;
        }
    }
    function dataExcel(){
        if(this.resumidoOption === 1 || this.resumidoOption === null){
            let listado = [];
            this.formatedItems.forEach(function (item,index) {
                listado.push({
                    '#': index + 1,
                    'CONTAINER CODE': item.ContainerCode ? item.ContainerCode : "",
                    'SIZE (FT)': item.TpCargoCode ? item.TpCargoCode : 0,
                    'ISO CODE': item.IsoCode ? item.IsoCode : "",
                    'SEALS': item.Seals ? item.Seals : "",
                    'STATUS': item.Status ? item.Status : "",
                    'CARRIER': item.ShippingLineCode ? item.ShippingLineCode : "",
                    'IMDG': item.ImdgCode ? item.ImdgCode : "",
                    'LOAD PORT': item.LoadPort ? item.LoadPort : "",
                    'DISCHARGE PORT': item.DischargePort ? item.DischargePort : "",
                    'DELIVERY PORT': item.DeliveryPort ? item.DeliveryPort : "",
                    'TRANSHIPMENT PORT': item.TranshipmentPort ? item.TranshipmentPort : "",
                    'WEIGHT (KGM)': item.Weigth ? item.Weigth : 0,
                    'VGM (KGM)': item.Vgm ? item.Vgm : 0,
                    'VGM CERTIFICATE': item.VgmCertificate ? item.VgmCertificate : "", 
                })
            });
            return listado;
        }else{
            let arreglo = [];
            this.formatedItems.map(async(item) => {
                arreglo.push([
                    item.ShippingLineCode,
                    item.HC20,
                    item.HC40,
                    item.HC45,
                    item.ST20,
                    item.ST40,
                    item.ST45,
                    item.RF20,
                    item.RF40,
                    item.RF45,
                    item.FT20,
                    item.FT40,
                    item.FT45,
                    item.OT20,
                    item.OT40,
                    item.OT45,
                    item.TK20,
                    item.TK40,
                    item.TK45,
                    item.PL20,
                    item.PL40,
                    item.PL45,
                ])
            })
            return arreglo;
        }
    }
    function modeTableGeneral(){
        let columnDefs = [
            {
                field: "ContainerCode",
                headerName: this.$t('label.containerCode'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "TpCargoCode",
                headerName: this.$t('label.size') + ' (FT)',
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "IsoCode",
                headerName: this.$t('label.isoCode'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "Seals",
                headerName: this.$t('label.Seal') + 's',
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "Status",
                headerName: this.$t('label.status'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "ShippingLineCode",
                headerName: this.$t('label.carrier'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            
            {
                field: "LoadPort",
                headerName: this.$t('label.loadPort'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "DischargePort",
                headerName: this.$t('label.dischargePort'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "DeliveryPort",
                headerName: this.$t('label.DeliveryPort'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "TranshipmentPort",
                headerName: this.$t('label.transhipmentPort'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "Weigth",
                headerName: this.$t('label.weight')+' (KGM)',
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "vgm",
                headerName: "VGM"+' (KGM)', 
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
            },
            {
                field: "VgmCertificate",
                headerName: this.$t('label.certificateVGM'),
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
        ]
        return columnDefs;
    }
    function modeTableResum(){
        let columnDefs = [
            {
                field: "ShippingLineCode",
                headerName: this.textFiltrarPor,
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                headerName: 'HC',
                headerClass: "cell-class-header",
                maxWidth:80,
                minWidth: 80,
                children: [
                    { field: 'HC20',headerName: "20",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'HC40',headerName: "40",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'HC45',headerName: "45",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                ]
            },
            {
                headerName: 'ST',
                headerClass: "cell-class-header",
                children: [
                    { field: 'ST20',headerName: "20",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'ST40',headerName: "40",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'ST45',headerName: "45",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                ]
            },
            {
                headerName: 'RF',
                headerClass: "cell-class-header",
                children: [
                    { field: 'RF20',headerName: "20",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'RF40',headerName: "40",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'RF45',headerName: "45",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                ]
            },
            {
                headerName: 'FT',
                headerClass: "cell-class-header",
                children: [
                    { field: 'FT20',headerName: "20",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'FT40',headerName: "40",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'FT45',headerName: "45",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                ]
            },
            {
                headerName: 'OT',
                headerClass: "cell-class-header",
                children: [
                    { field: 'OT20',headerName: "20",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'OT40',headerName: "40",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'OT45',headerName: "45",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                ]
            },
            {
                headerName: 'TK',
                headerClass: "cell-class-header",
                children: [
                    { field: 'TK20',headerName: "20",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'TK40',headerName: "40",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'TK45',headerName: "45",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                ]
            },
            {
                headerName: 'PL',
                headerClass: "cell-class-header",
                children: [
                    { field: 'PL20',headerName: "20",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'PL40',headerName: "40",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                    { field: 'PL45',headerName: "45",minWidth: 60,width: 60,cellClass: 'gb-cell', },
                ]
            },
        ]
        return columnDefs;
    }
    function formatedItems(){
        let computedItems = [];
        let listItems = this.FileComparationList;
        if(this.resumidoOption === 1 || this.resumidoOption === null){
            listItems.map(async(item) => {
                computedItems.push({
                    ContainerCode: item.ContainerCode,
                    Size: item.Size ? item.Size : '',
                    TpCargoCode: item.TpCargoCode ? item.TpCargoCode : '',
                    Status: item.Status ? item.Status : '',
                    IsoCode: item.IsoCode ? item.IsoCode : '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    DischargePort: item.DischargePort ? item.DischargePort : '',
                    DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '',
                    TranshipmentPort: item.TranshipmentPort ? item.TranshipmentPort : '',
                    Weigth: formatMilDecimal(parseFloat(item.Weigth).toFixed(2)),
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    TpCargoCode: item.TpCargoCode ? item.TpCargoCode : '',
                    TpCargoGroupCode: item.TpCargoGroupCode ? item.TpCargoGroupCode : '',
                    Vgm: item.Vgm ? item.Vgm : '',
                    VgmCertificate: item.VgmCertificate ? item.VgmCertificate : '',
                    UnitMeasureVgm: item.UnitMeasureVgm ? item.UnitMeasureVgm : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    Seals: item.Seals ? item.Seals : '',
                })
            })
        }else{
            if(this.filtrarOption === 1){
                computedItems = listItems;
            }
            else{
                let label = this.$i18n.locale;
                listItems.map(async(item) => {
                    item.ShippingLineCode =  label === 'es' ? item.StatusEs : item.StatusEn
                })
                computedItems = listItems;
            }
        }
        return computedItems.filter(item => {
            return item
        })
    }
    function optionListLoadAll(){
        if (this.ComparationLoad.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.ComparationLoad.map(function(e) {
                chart.push({
                    value: e.PortId,
                    label: e.PortCode,
                });
            });
            return chart;
        }
    }
    function optionListDischargeAll(){
        if (this.ComparationDischarge.length === 0) {
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
            this.ComparationDischarge.map(function(e) {
                chart.push({
                    value: e.PortId,
                    label: e.PortCode,
                });
            });
            return chart;
        }
    }
    function optionListVerAll(){
        if(!this.showComparationGroup){
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        }else{
            return [
                {
                    value:1,
                    label: this.$t('label.general'),
                },
                {
                    value:2,
                    label: this.$t('label.resumido'),
                }
            ]
        }
    }
    function optionListFiltrarAll(){
        if(!this.showComparationGroup || !(this.resumidoOption == 2)){
            return [
                {
                    value:null,
                    label: this.$t('label.selectIt'),
                },
            ];
        }else{
            return [
                {
                    value:1,
                    label: this.$t('label.newShippingLine'),
                },
                {
                    value:2,
                    label: this.$t('label.status'),
                }
            ]
        }
    }
    export default {
        name:'comparacionListados',
        props: ['value'],
        mixins: [Reportes, AgGrid],
        data,
        methods:{
            onGridReady,
            abrirModal,
            changePort,
            changeVerPort,
            onBtnExport,
            getExcelArray2,
            resetValues
        },
        beforeMount,
        components:{
            modalComparacionList,
        },
        computed:{
            textPortDescarga,
            textPortCarga,
            textResumido,
            textFiltrarPor,
            dataExcel,
            formatedItems,
            modeTableGeneral,
            modeTableResum,
            optionListLoadAll,
            optionListDischargeAll,
            optionListVerAll,
            optionListFiltrarAll,
            ...mapState({
                planificacionId: (state) => state.planificacionestiba.planificacionId,
                ComparationLoad: (state) => state.planificacionestiba.ComparationLoad,
                ComparationDischarge: (state) => state.planificacionestiba.ComparationDischarge,
                showComparationGroup: (state) => state.planificacionestiba.showComparationGroup,
                FileComparationList: (state) => state.planificacionestiba.FileComparationList,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
            })
        },
        watch:{
            value(newValue,OldValue){
                if(newValue === 3){
                    this.$store.dispatch('planificacionestiba/getComparationList', { StowagePlanningId: this.planificacionId });
                }else if(OldValue === 3){
                    this.$store.state.planificacionestiba.showComparationGroup = false;
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    }
</script>

<style lang="scss">
    .cell-class-header{
        .ag-header-group-cell-label{
            justify-content: center;
        }
    }
</style>