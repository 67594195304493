<template>
    <CRow style="margin-top: 20px">
        <CCol sm="12">
            <previewModal :preliminarList="dateFilteredItems" />
            <CRow>
                <CCol sm="6">
                    <CSelect
                        :label="$t('label.loadBaplie')"
                        :horizontal="{ label: 'col-sm-auto', input: 'col-sm-8'}"
                        :value.sync="selectBaplie"
                        :options="optionsListBaplie"
                    >
                    </CSelect>
                </CCol>
                <CCol sm="6" v-if="selectBaplie !== null" class="d-flex align-items-center justify-content-end">
                    <CSelect
                        :label="$t('label.Default')"
                        class="col-sm-12 p-0"
                        addLabelClasses="text-right"
                        :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                        :value.sync="selectDefault"
                        @change="checkDefaultValue"
                        :options="optionsListDefault"
                    >
                    </CSelect>
                </CCol>
            </CRow>
            <CRow class="mb-3">
                <CCol sm="12">
                    <CRow>
                        <CCol sm="6" class="d-flex align-items-center">
                            <label style="margin-bottom:0px;" class="col-form-label">{{$t('label.auxiliary_file')}}</label>
                            <CButton
                                size="sm"
                                color="edit" class="ml-4"
                                v-c-tooltip="{
                                    content: $t('label.toView')+' '+$t('label.auxiliary_file'),
                                    placement: 'top-end'
                                }"
                                @click="changeCollapse()"
                            >
                                <CIcon :name="typecheck"/>
                            </CButton>
                        </CCol>
                        <CCol sm="6" class="d-flex align-items-center justify-content-end formEspecialDefault">
                            <CSelect
                                label=""
                                addInputClasses="justify-content-end"
                                :placeholder="$t('label.selectFilter')"
                                class="col-sm-12 mb-0"
                                :horizontal="{ label: 'col-sm-5', input: 'col-sm-6'}"
                                :value.sync="selectFiltro"
                                :options="optionsListFiltro"
                                addLabelClasses="text-right"
                            >
                                <template #prepend-content>
                                    <CIcon name="cil-filter" />
                                </template>
                            </CSelect>
                            <CButton
                                color="edit"
                                size="sm"
                                class="d-flex align-items-center"
                                v-c-tooltip="{
                                    content: $t('label.reset')+$t('label.table'),
                                    placement: 'top',
                                }"
                                @click="refresTable"
                            >
                                <CIcon name="cil-loop-circular" />
                            </CButton>
                        </CCol>
                    </CRow>
                </CCol>
                <CCol sm="12" v-if="checkListEdi" style="margin-bottom:10px" class="mt-2">
                    <CCollapse :show="checkListEdi">
                        <CRow>
                            <CCol sm="3" class="border-shadow" v-for="item in checkOptionsList" :key="item.EdiFileId">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-sm-9 col-form-label col-form-label-sm">{{item.EdiFileName}}</label>
                                    <div class="col-sm-3 d-flex align-items-center justify-content-end">
                                        <CSwitch
                                            v-if="selectBaplie !== null"
                                            color="info"
                                            variant="3d"
                                            size="sm"
                                            type="checkbox"
                                            :checked.sync="item.CheckItem"
                                            v-on:update:checked="changeItemOptions(item)"
                                            :id="item.IdName"
                                            :name="item.Name"
                                        />
                                        <CSwitch
                                            v-else
                                            color="info"
                                            variant="3d"
                                            size="sm"
                                            type="checkbox"
                                            :checked.sync="item.CheckItem"
                                            v-on:update:checked="changeItemOptions(item)"
                                            
                                        />
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                    </CCollapse>
                </CCol>

            </CRow>
        </CCol>
        <CCol sm="12">
            <dataTableExtended
                class="align-center-row-datatable table-lv-1 table-responsive"
                hover
                sorter
                small
                :loading="apiStateLoading"
                column-filter
                :table-filter="tableTextHelpers.tableFilterText"
                :items-per-page-select="tableTextHelpers.itemsPerPageText"
                :items="computedFilters"
                details
                :fields="fields"
                :noItemsView="tableTextHelpers.noItemsViewText"
                :items-per-page="5"
                pagination
            >
                <template #show_details="{item}">
                    <td class="text-center" style="padding:0px;">
                        <CButton
                            v-if="item.DetailJson.length !== 0"
                            block
                            color="light"
                            class="boton_informacion"
                            size="sm"
                            @click="toggleLevel(item)"
                        >
                            <CIcon name="cil-chevron-bottom" />
                        </CButton>
                    </td>
                </template>
                <template #id="{ index}">
                    <td class="center-cell text-center">
                    {{index+1}}
                    </td>
                </template>
                <template #Size="{ item }">
                    <td :class="textType(item,'Size')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.Size)}}&nbsp;<img v-if="booleanType(item,'Size')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #Status="{ item }">
                    <td :class="textType(item,'Status')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.Status)}}&nbsp;<img v-if="booleanType(item,'Status')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #IsoCode="{ item }">
                    <td :class="textType(item,'IsoCode')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.IsoCode)}}&nbsp;<img v-if="booleanType(item,'IsoCode')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #ImdgCode="{ item }">
                    <td :class="textType(item,'ImdgCode')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.ImdgCode)}}&nbsp;<img v-if="booleanType(item,'ImdgCode')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #LoadPort="{ item }">
                    <td :class="textType(item,'LoadPort')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.LoadPort)}}&nbsp;<img v-if="booleanType(item,'LoadPort')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #DischargePort="{ item }">
                    <td :class="textType(item,'DischargePort')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.DischargePort)}}&nbsp;<img v-if="booleanType(item,'DischargePort')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #DeliveryPort="{ item }">
                    <td :class="textType(item,'DeliveryPort')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.DeliveryPort)}}&nbsp;<img v-if="booleanType(item,'DeliveryPort')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #TranshipmentPort="{ item }">
                    <td :class="textType(item,'TranshipmentPort')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.TranshipmentPort)}}&nbsp;<img v-if="booleanType(item,'TranshipmentPort')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #Seal1="{ item }">
                    <td :class="textType(item,'Seal1')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.Seal1)}}&nbsp;<img v-if="booleanType(item,'Seal1')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #Seal2="{ item }">
                    <td :class="textType(item,'Seal2')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.Seal2)}}&nbsp;<img v-if="booleanType(item,'Seal2')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #Seal3="{ item }">
                    <td :class="textType(item,'Seal3')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.Seal3)}}&nbsp;<img v-if="booleanType(item,'Seal3')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #Seal4="{ item }">
                    <td :class="textType(item,'Seal4')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.Seal4)}}&nbsp;<img v-if="booleanType(item,'Seal4')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #Weigth="{ item }">
                    <td :class="textType(item,'Weigth')">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.Weigth)}}&nbsp;<img v-if="booleanType(item,'Weigth')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #Vgm="{ item }">
                    <td :class="textType(item,'Vgm')+' text-left'">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.Vgm)}}&nbsp;<img v-if="booleanType(item,'Vgm')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #VgmCertificate="{ item }" >
                    <td :class="textType(item,'VgmCertificate')+' text-left'">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.VgmCertificate)}}&nbsp;<img v-if="booleanType(item,'VgmCertificate')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #Origin="{ item }" >
                    <td :class="textType(item,'Origin')+' text-left'">
                        <div class="d-flex justify-content-center align-items-center" >
                            {{valueReal(item.Origin)}}&nbsp;<img v-if="booleanType(item,'Origin')" class="img-fluid rounded" height="20px" width="20px" src="/img/warning_icon.png" @error="setAltImg" alt="Card image cap">
                        </div>
                    </td>
                </template>
                <template #details="{item}">
                    <CCollapse
                        :show="Boolean(item._toggled)"
                        :duration="collapseDuration"
                    >
                        <dataTableExtended
                            class="align-center-row-datatable table-lv-2 table-responsive"
                            :items="formattedNivel2"
                            :fields="fieldslv1and2"
                            :items-per-page="5"
                            sorter
                            pagination
                            :table-filter="tableTextHelpers.tableFilterText"
                            :items-per-page-select="tableTextHelpers.itemsPerPageText"
                            :noItemsView="tableTextHelpers.noItemsViewText"
                        >
                            <template #EdiFileName="{ item }">
                                <td >
                                    <div class="form-check" v-if="item.StowagePlanningCargoId !== realValue.StowagePlanningCargoId">
                                        <input class="form-check-input" 
                                            @change="changeCheckError(item)"
                                            type="radio" 
                                            :key="item.Id"
                                            :name="item.ContainerCode"
                                            :disabled="item.StowagePlanningCargoId === realValue.StowagePlanningCargoId"
                                        >
                                        <label class="form-check-label" :for="item.Id">
                                            {{item.EdiFileName}}
                                        </label>
                                    </div>
                                    <div v-else
                                    >
                                        <CIcon name="cil-check-circle" /> <b style="font-weight: bold;">{{ item.EdiFileName }}</b>
                                    </div>
                                </td>
                            </template>
                        </dataTableExtended>
                    </CCollapse>
                </template>

                <!--template slot="under-table">
                    <div v-if="computedFilters.length !== 0">
                        <h6>{{$t('label.totalQuantity')+': '+computedFilters.length}}</h6>
                    </div>
                </template-->
            </dataTableExtended>
        </CCol>
        <CCol sm="12" class="d-flex align-items-center justify-content-end" v-if="computedFilters.length !== 0">
            <CButton 
                color="watch"
                class="mb-2"
                :disabled="apiStateLoading"
                @click.stop="previewListView()"
            >
                <CIcon name="cil-eye"/>&nbsp; {{$t('button.preview')}}
            </CButton>
        </CCol>
    </CRow>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import previewModal from './preview-modal';
    //data
    function data() {
        return {
            collapseDuration: 0,
            defaultList:[],
            selectDefault:null,
            changeCheckList:[],
            preliminarList:[],
            itemsNivelII:[],
            checkOptionsList:[],
            EdiAll:[],
            baplieAll:[],
            realValue:{},
            selectBaplie:null,
            selectFiltro:null,
            checkListEdi:false,
            banderaReal:false,
        }
    }
    //methods
    function setAltImg(event){
        event.target.src = '/img/errorimage.jpg';
    }
    function previewListView(){
        this.$store.state.planificacionestiba.modalPreliminarList = true;
    }
    function refresTable(){
        this.preliminarList = [];
        this.selectDefault = null;
        this.selectBaplie=null;
        this.selectFiltro=null;
        this.checkListEdi = false;
        if(this.checkOptionsList.length !== 0){
            this.checkOptionsList = this.checkOptionsList.map((item) => Object.assign({}, item, {
                CheckItem: false
            }));
        }
    }
    async function changeItemOptions(item){
        this.preliminarList = [];
        if(this.selectBaplie !== null){
            if(this.checkOptionsList.length !== 0){
                this.checkOptionsList.map(async(value,index) => {
                    if(value.TpEdiTransacName == item.TpEdiTransacName){
                        if(value.EdiFileId == item.EdiFileId){
                            value.CheckItem = item.CheckItem;
                        }else{
                            if(item.CheckItem){
                                value.CheckItem = false;
                            }
                        }
                    }
                })

                let items = this.checkOptionsList;
                let EdiFileAuxJson = [];
                let bandera = false;

                items.map(async (value) => {
                    if(value.CheckItem === true){
                        EdiFileAuxJson.push({
                            EdiFileAuxId : value.EdiFileId
                        });
                        bandera = true;
                    }
                })
                if(bandera){
                    await this.comparationMethod(EdiFileAuxJson);
                    await this.changeListSelected();
                }
            }
        }else{
            if(this.checkOptionsList.length !== 0){
                let items = this.checkOptionsList;
                let EdiFileAuxJson = [];
                let bandera = false;

                items.map(async (value) => {
                    if(value.CheckItem === true){
                        EdiFileAuxJson.push({
                            EdiFileAuxId : value.EdiFileId
                        });
                        bandera = true;
                    }
                });
                if(bandera){
                    await this.comparationMethod(EdiFileAuxJson);
                }
            }
        }
    }
    function changeListSelected(){
        const idValue = this.selectDefault == null || this.selectDefault == undefined ? "1" : this.selectDefault.toString();
        let moduleOpen;
        moduleOpen = this.formattedNivel2.find((val) => val.selected == true);
        if (moduleOpen != undefined){
            moduleOpen.selected = false;
        }

        this.dateFilteredItems.map(async(value) => {
            if(value.DetailJson.length !== 0){
                value.DetailJson.map(async(item) => {
                    if(idValue === "1"){
                        if(!item.FgAuxFile){
                            item.selected = true;

                            value.DischargePort = item.DischargePort;
                            value.DischargePortId = item.DischargePortId;
                            value.DeliveryPort = item.DeliveryPort;
                            value.DeliveryPortId = item.DeliveryPortId;
                            value.Origin = item.Origin;
                            value.Vgm = item.Vgm;
                            value.VgmCertificate = item.VgmCertificate;
                            value.Weigth = item.Weigth;
                            value.UnitMeasureVgm = item.UnitMeasureVgm;
                            value.UnitMeasureWeigth = item.UnitMeasureWeigth;
                            value.TpEdiName = item.TpEdiName;
                            value.EdiFileId = item.EdiFileId;
                            value.EdiFileName = item.EdiFileName;
                            value.GpoCargoId = item.GpoCargoId;
                            value.ImdgCode = item.ImdgCode;
                            value.ImdgId = item.ImdgId;
                            value.IsoCode = item.IsoCode;
                            value.LoadPort = item.LoadPort;
                            value.LoadPortId = item.LoadPortId;
                            value.Seal1 = item.Seal1;
                            value.Seal2 = item.Seal2;
                            value.Seal3 = item.Seal3;
                            value.Seal4 = item.Seal4;
                            value.MetadataId = item.MetadataId;
                            value.ShippingLineCode = item.ShippingLineCode;
                            value.ShippingLineId = item.ShippingLineId;
                            value.Size = item.Size;
                            value.SizeId = item.SizeId;
                            value.Status = item.Status;
                            value.StowagePlanningCargoId = item.StowagePlanningCargoId;
                            value.TpCargoCode = item.TpCargoCode;
                            value.TpCargoGroupCode = item.TpCargoGroupCode;
                            value.TpCargoId = item.TpCargoId;
                            value.TpCargoStatusId = item.TpCargoStatusId;
                            value.FgAuxFile = item.FgAuxFile;
                        }else{
                            item.selected = false;
                        }
                    }else if(idValue === "2"){
                        if(item.FgAuxFile){
                            item.selected = true;

                            value.DischargePort = item.DischargePort;
                            value.DischargePortId = item.DischargePortId;
                            value.DeliveryPort = item.DeliveryPort;
                            value.DeliveryPortId = item.DeliveryPortId;
                            value.Origin = item.Origin;
                            value.Vgm = item.Vgm;
                            value.VgmCertificate = item.VgmCertificate;
                            value.Weigth = item.Weigth;
                            value.UnitMeasureVgm = item.UnitMeasureVgm;
                            value.UnitMeasureWeigth = item.UnitMeasureWeigth;
                            value.TpEdiName = item.TpEdiName;
                            value.EdiFileId = item.EdiFileId;
                            value.EdiFileName = item.EdiFileName;
                            value.GpoCargoId = item.GpoCargoId;
                            value.ImdgCode = item.ImdgCode;
                            value.ImdgId = item.ImdgId;
                            value.IsoCode = item.IsoCode;
                            value.LoadPort = item.LoadPort;
                            value.LoadPortId = item.LoadPortId;
                            value.Seal1 = item.Seal1;
                            value.Seal2 = item.Seal2;
                            value.Seal3 = item.Seal3;
                            value.Seal4 = item.Seal4;
                            value.MetadataId = item.MetadataId;
                            value.ShippingLineCode = item.ShippingLineCode;
                            value.ShippingLineId = item.ShippingLineId;
                            value.Size = item.Size;
                            value.SizeId = item.SizeId;
                            value.Status = item.Status;
                            value.StowagePlanningCargoId = item.StowagePlanningCargoId;
                            value.TpCargoCode = item.TpCargoCode;
                            value.TpCargoGroupCode = item.TpCargoGroupCode;
                            value.TpCargoId = item.TpCargoId;
                            value.TpCargoStatusId = item.TpCargoStatusId;
                            value.FgAuxFile = item.FgAuxFile;
                        }else{
                            item.selected = false;
                        }
                    }
                })
            }
        });
    }
    function checkDefaultValue(event){
        if(event.target !== undefined){
            this.changeListSelected();
        }
    }
    function changeCheckError(item){
        let moduleOpen;
        moduleOpen = this.formattedNivel2.find((val) => val.selected == true);
        if (moduleOpen != undefined){
            moduleOpen.selected = false;
        }
        item.selected = !item.selected;
        
        if(item.selected){
            /*this.realValue.EdiFileAuxId = item.EdiFileAuxId;
            this.realValue.EdiFileName = item.EdiFileName;
            this.realValue.FgAuxFile = item.FgAuxFile;*/
            this.realValue.DischargePort = item.DischargePort;
            this.realValue.DischargePortId = item.DischargePortId;
            this.realValue.DeliveryPort = item.DeliveryPort;
            this.realValue.DeliveryPortId = item.DeliveryPortId;
            this.realValue.Origin = item.Origin;
            this.realValue.Vgm = item.Vgm;
            this.realValue.VgmCertificate = item.VgmCertificate;
            this.realValue.Weigth = item.Weigth;
            this.realValue.UnitMeasureVgm = item.UnitMeasureVgm;
            this.realValue.UnitMeasureWeigth = item.UnitMeasureWeigth;
            this.realValue.TpEdiName = item.TpEdiName;
            this.realValue.EdiFileId = item.EdiFileId;
            this.realValue.EdiFileName = item.EdiFileName;
            this.realValue.GpoCargoId = item.GpoCargoId;
            this.realValue.ImdgCode = item.ImdgCode;
            this.realValue.ImdgId = item.ImdgId;
            this.realValue.IsoCode = item.IsoCode;
            this.realValue.LoadPort = item.LoadPort;
            this.realValue.LoadPortId = item.LoadPortId;
            this.realValue.Seal1 = item.Seal1;
            this.realValue.Seal2 = item.Seal2;
            this.realValue.Seal3 = item.Seal3;
            this.realValue.Seal4 = item.Seal4;
            this.realValue.MetadataId = item.MetadataId;
            this.realValue.ShippingLineCode = item.ShippingLineCode;
            this.realValue.ShippingLineId = item.ShippingLineId;
            this.realValue.Size = item.Size;
            this.realValue.SizeId = item.SizeId;
            this.realValue.Status = item.Status;
            this.realValue.StowagePlanningCargoId = item.StowagePlanningCargoId;
            this.realValue.TpCargoCode = item.TpCargoCode;
            this.realValue.TpCargoGroupCode = item.TpCargoGroupCode;
            this.realValue.TpCargoId = item.TpCargoId;
            this.realValue.TpCargoStatusId = item.TpCargoStatusId;
            this.realValue.FgAuxFile = item.FgAuxFile;

            const Index = this.dateFilteredItems.map(function(e) {
                return e.ContainerCode; 
            }).indexOf(item.ContainerCode);
            if (Index !== -1){
                this.dateFilteredItems[Index] = this.realValue;
            }
            
        }
        //item._classes = item.selected ? 'table-info' : '';
    }
    function valueReal(value){
        return value !== null ? value : '';
    }
    function typeNameButtonList(item){
        return item !== null ? (Boolean(item._toggled) ? 'cil-chevron-right' : 'cil-chevron-bottom') : 'cil-chevron-bottom';
    }
    function booleanType(item,Key){
        const Index = item.DifferenceJson.map(function(e) {
            return e.Key; 
        }).indexOf(Key);
        
        if(Index > -1){
            return true;
        }else{
            return false;
        }
    }
    function textType(item,Key){
        const Index = item.DifferenceJson.map(function(e) {
            return e.Key; 
        }).indexOf(Key);
        
        if(Index > -1){
            return 'text-warning';
        }else{
            return '';
        }
    }
    function closeShow(item) {
        let moduleOpen;
        moduleOpen = this.dateFilteredItems.find((val) => val._toggled == true);
        if (moduleOpen != undefined && moduleOpen.ContainerCode != item.ContainerCode) {
            this.itemsNivelII = [];
            moduleOpen._toggled = !moduleOpen._toggled;
        }
    }
    async function toggleLevel(item) {
        await this.closeShow(item);

        const modulePadre = this.preliminarList.find(
            (val) => val.ContainerCode === item.ContainerCode
        );
        
        this.realValue = item;
        this.itemsNivelII = item.DetailJson;

        this.collapseDuration = 300;
        item._toggled = !item._toggled;
        this.$nextTick(() => {
            this.collapseDuration = 0;
        });
    }
    async function comparationMethod(EdiFileAuxJson){
        if(EdiFileAuxJson.length > 0){
            let CargoAuxFileJon = {
                EdiFileId: this.selectBaplie,
                EdiFileAuxJson
            }
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;

            await this.$http.ejecutar("POST", "StowagePlanningCargoAuxFileComparison", CargoAuxFileJon, { root: 'CargoAuxFileJon' })
            .then(response => {
                if(response.data.status === 200){
                    this.preliminarList = response.data.data;
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                }else{
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                this.$store.commit('maquina/messageMutation', err);
            })
        }
    }
    
    function selectBaplie(newValue){
        if(this.value === 2){
            if(newValue === null){
                this.selectDefault=null;
                if(this.changeCheckList.length !== 0){
                    let items = this.changeCheckList;
                    let EdiFileAuxJson = [];
                    let bandera = false;

                    items.map(async (value) => {
                        if(value.CheckItem === true){
                            EdiFileAuxJson.push({
                                EdiFileAuxId : value.EdiFileId
                            });
                            bandera = true;
                        }
                    })
                    if(bandera){
                        this.comparationMethod(EdiFileAuxJson);
                    }
                }
            }else{
                this.selectDefault=null;
                this.checkOptionsList = this.checkOptionsList.map((item) => Object.assign({}, item, {
                    CheckItem: false
                }));
                this.preliminarList = [];
            }
        }
    }
    function changeCollapse(){
        this.checkListEdi = !this.checkListEdi;
    }
    async function listarBaplie () {
        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'StowagePlanningEdiFileBaplie-list', { StowagePlanningId:this.planificacionId,TpEdiTransacId:'0bdb045b-f7cf-4782-8acf-ac04a1b342cf' }, '').then(responsebaplieAll => {
            if(responsebaplieAll.status === 200){
                this.$http.ejecutar('GET', 'StowagePlanningEdiFileAuxiliar-list', { StowagePlanningId:this.planificacionId }, '').then(responseEdiAll => {
                    if(responseEdiAll.status === 200){
                        this.EdiAll = responseEdiAll.data.data;
                        this.baplieAll = responsebaplieAll.data.data;
                        if (this.EdiAll.length === 0) {
                            this.checkOptionsList = [];
                        }else{
                            this.checkOptionsList = this.EdiAll.map((item,index) => Object.assign({}, item, {
                                IdName: item.TpEdiTransacName.replaceAll(" ","_")+index,
                                Name: item.TpEdiTransacName.replaceAll(" ","_"),
                                checkFull: item.TpEdiTransacName === "FULL LOAD LIST" ? true : false,
                                checkEmpty: item.TpEdiTransacName === "EMPTY LOAD LIST" ? true : false,
                                CheckItem: false
                            }));
                           // console.log(this.checkOptionsList);
                        }
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    }else{
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    }
                }).catch(err => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    this.$store.commit('maquina/messageMutation', err);
                });
            }else{
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            this.$store.commit('maquina/messageMutation', err);
        });
    }
    //computed
    function fields(){
        return [
            { key: 'show_details', label:'',_classes:"center-cell", _style:'width:50px;text-align: center;',sorter: false,filter: false },
            { key: 'id', label: '#',filter :false, _style:'width:1%; text-align: center;' },
            { key: 'ContainerCode', label: this.$t('label.containerCode'),_classes:"center-cell text-left", _style:'min-width:120px;text-align: left;'},
            { key: 'Size', label: this.$t('label.size')+' (FT)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'IsoCode', label: this.$t('label.isoCode'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal1', label: this.$t('label.Seal')+' 1',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal2', label: this.$t('label.Seal')+' 2',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal3', label: this.$t('label.Seal')+' 3',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal4', label: this.$t('label.Seal')+' 4',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Status', label: this.$t('label.status'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'ShippingLineCode', label: this.$t('label.carrier'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'ImdgCode', label: 'IMDG',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'LoadPort', label: 'POL',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'DischargePort', label: 'POD',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'DeliveryPort', label: 'PODEL',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'TranshipmentPort', label: 'POT',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Weigth', label: this.$t('label.weight')+' (KGM)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Vgm', label: 'VGM (KGM)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'VgmCertificate', label: this.$t('label.certificateVGM'),_classes:"center-cell text-left", _style:'min-width:120px;text-align: left;'},
            { key: 'Origin', label: this.$t('label.Origin'),_classes:"center-cell", _style:'min-width:120px;'},
        ]
    }
    function fieldslv1and2(){
        return [
            { key: 'EdiFileName', label: 'BAPLIE',_classes:"center-cell text-left", _style:'min-width:100px;'},
            { key: 'ContainerCode', label: this.$t('label.containerCode'),_classes:"center-cell text-left", _style:'min-width:120px;text-align: left;'},
            { key: 'Size', label: this.$t('label.size')+' (FT)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'IsoCode', label: this.$t('label.isoCode'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal1', label: this.$t('label.Seal')+' 1',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal2', label: this.$t('label.Seal')+' 2',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal3', label: this.$t('label.Seal')+' 3',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Seal4', label: this.$t('label.Seal')+' 4',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Status', label: this.$t('label.status'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'ShippingLineCode', label: this.$t('label.carrier'),_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'ImdgCode', label: 'IMDG',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'LoadPort', label: 'POL',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'DischargePort', label: 'POD',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'DeliveryPort', label: 'PODEL',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'TranshipmentPort', label: 'POT',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Weigth', label: this.$t('label.weight')+' (KGM)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'Vgm', label: 'VGM (KGM)',_classes:"center-cell", _style:'min-width:120px;'},
            { key: 'VgmCertificate', label: this.$t('label.certificateVGM'),_classes:"center-cell text-left", _style:'min-width:120px;text-align: left;'},
            { key: 'Origin', label: this.$t('label.Origin'),_classes:"center-cell", _style:'min-width:120px;'},
        ]
    }
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function dateFilteredItems(){
        let itemsList = [];
        
        this.preliminarList.map(async (item,index) =>{
            if(item.CargoJson !== undefined){
                if(item.CargoJson.length !== 0){
                    itemsList.push({
                        id: index+1,
                        ...item.CargoJson[0],
                        ContainerCode: item.ContainerCode,
                        ImdgCode: item.CargoJson[0].ImdgCode ? item.CargoJson[0].ImdgCode : '',
                        Size: item.CargoJson[0].Size ? item.CargoJson[0].Size : '',
                        Status: item.CargoJson[0].Status ? item.CargoJson[0].Status : '',
                        ShippingLineCode: item.CargoJson[0].ShippingLineCode ? item.CargoJson[0].ShippingLineCode : '',
                        IsoCode: item.CargoJson[0].IsoCode ? item.CargoJson[0].IsoCode : '',
                        DeliveryPort: item.CargoJson[0].DeliveryPort ? item.CargoJson[0].DeliveryPort : '',
                        Origin: item.CargoJson[0].Origin ? item.CargoJson[0].Origin : '',
                        TranshipmentPort: item.CargoJson[0].TranshipmentPort ? item.CargoJson[0].TranshipmentPort : '',
                        Vgm: item.CargoJson[0].Vgm ? item.CargoJson[0].Vgm : '',
                        VgmCertificate: item.CargoJson[0].VgmCertificate ? item.CargoJson[0].VgmCertificate : '',
                        DetailJson: item.DetailJson !== null ? item.DetailJson : [],
                        DifferenceJson: item.DifferenceJson !== null ? item.DifferenceJson : [],
                        DifferenceJsonBandera : item.FgDifference,
                        _classes: 'table-secondary',
                        _toggled: false,
                    })
                }
            }
        })
        
        return itemsList;
    }
    function formattedNivel2(){
        if(this.itemsNivelII.length !== 0){
            let itemList = [];
            itemList = this.itemsNivelII.map((item,Index) => Object.assign({}, item, {
                Id: Index,
                EdiFileAuxId: item.EdiFileAuxId,
                EdiFileName: item.EdiFileName,
                ContainerCode: this.realValue.ContainerCode ? this.realValue.ContainerCode : '',
                Size: item.Size ? item.Size : '',
                Status: item.Status ? item.Status : '',
                ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                IsoCode: item.IsoCode ? item.IsoCode : '',
                ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                LoadPort: item.LoadPort ? item.LoadPort : '',
                DischargePort: item.DischargePort ? item.DischargePort : '',
                TranshipmentPort: item.TranshipmentPort ? item.TranshipmentPort : '',
                DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '',
                Origin: item.Origin ? item.Origin : '',
                Seal1: item.Seal1 ? item.Seal1 : "",
                Seal2: item.Seal2 ? item.Seal2 : "",
                Seal3: item.Seal3 ? item.Seal3 : "",
                Seal4: item.Seal4 ? item.Seal4 : "",
                Weigth: item.Weigth ? item.Weigth : 0,
                Vgm: item.Vgm ? item.Vgm : 0,
                VgmCertificate: item.VgmCertificate ? item.VgmCertificate : '',
                selected: false,
                _classes: '',
            }));
            return itemList;
        }else{
            return [];
        }
    }
    function computedFilters(){
        let filters = this.selectFiltro;
        return this.dateFilteredItems.filter(item => {
            if(filters === null){
                return item
            }
            else if(filters === 0){
                return item.DifferenceJsonBandera === true
            }
            else if(filters === 1){
                return item.DifferenceJsonBandera === false
            }
        })
    }
    function typecheck(){
       // return this.checkListEdi ? 'cil-caret-bottom' : 'cil-caret-right';
        return this.checkListEdi ? 'minus' : 'cil-plus';
    }
    function optionsListDefault() {
        return [
            {
                value: null,
                label: this.$t('label.select'),
            },
            {
                value: 1,
                label: this.$t('label.fileBaplie'),
            },
            {
                value: 2,
                label: this.$t('label.auxiliary_file'),
            }
        ];
    }
    function optionsListBaplie() {
        if (this.baplieAll.length === 0) {
            return [
                {
                    value: null,
                    label: this.$t('label.select'),
                },
            ];
        } else {
            const chart = [
                {
                    value:null,
                    label: this.$t('label.select'),
                },
            ];
            this.baplieAll.map(function(e) {
                chart.push({
                    value: e.EdiFileId,
                    label: e.EdiFileName,
                });
            });
            return chart;
        }
    }
    function optionsListFiltro() {
        return [
            {
                value: null,
                label: this.$t('label.select'),
            },
            {
                value: 0,
                label: this.$t('label.filter_preliminar.see_discrepancies'),
            },
            {
                value: 1,
                label: this.$t('label.filter_preliminar.see_matches'),
            },
        ];
    }
    export default {
        name:'preliminarLoad-list',
        props: ['value'],
        data,
        methods:{
            previewListView,
            checkDefaultValue,
            changeListSelected,
            changeCheckError,
            booleanType,
            setAltImg,
            textType,
            valueReal,
            closeShow,
            toggleLevel,
            typeNameButtonList,
            comparationMethod,
            changeCollapse,
            listarBaplie,
            refresTable,
            changeItemOptions
        },
        computed:{
            apiStateLoading,
            typecheck,
            fields,
            dateFilteredItems,
            computedFilters,
            fieldslv1and2,
            formattedNivel2,
            tableTextHelpers,
            optionsListBaplie,
            optionsListDefault,
            optionsListFiltro,
            ...mapState({
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                EdiFileId: (state) => state.planificacionestiba.EdiFileId,
                EdiFileInfo: (state) => state.planificacionestiba.EdiFileInfo,
                planificacionId: state => state.planificacionestiba.planificacionId,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
            })
        },
        components: {
            previewModal
        },
        watch:{
            value(newValue,OldValue){
                if(newValue === 2){
                    this.listarBaplie();
                }else if(OldValue === 2){
                    this.preliminarList = [];
                    this.selectBaplie = null;
                    this.selectFiltro = null;
                }
            },
            selectBaplie
        }
    }
</script>

<style lang="scss">
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .not-margin-slot{
        .form-group {
            margin-bottom: 0px;
        }
    }
    .center-cell {
  text-align: center;
}
 
.boton_informacion{
    box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
}
.formEspecialDefault{
    .col-sm-12{
        justify-content: end;
    }
    .form-group {
        .col-sm-6{
            flex: 0 0 54%;
            max-width: 54%;
        }
    }
}
</style>